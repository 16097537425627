<template>
  <div class="col-md-6" v-if="denominazione_impianto">
    <h3 class="ps-5 pb-2">{{ denominazione_impianto }}</h3>
  </div>
  <div class="row mt-3">
    <div class="col-md-6">
      <router-link
        :to="`/enti-aggregati/dettaglio-ente/impianti-ente/${id_societa}`"
        class="btn btn-light text-gray-700 fs-6 text-start"
      >
        <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista impianti ente
      </router-link>
    </div>
    <div class="col-md-6 text-end">
      <div v-if="isEnabled('fnImpiantiAddOmologazione')">
        <!-- <router-link
          :to="`/societa/dettaglio-societa/impianti-societa/omologazioni-impianto/nuova-omologazione/${id_societa}`"
        >
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>nuova omologazione
          </button></router-link
        > -->
        <router-link
          :to="{
            name: 'nuova-omologazione-ente',
            params: {
              id: id_societa,
              denominazione_impianto: denominazione_impianto,
            },
          }"
          class="btn btn-light text-gray-700 fs-6 text-start"
        >
          <i class="bi bi-plus fs-6 text-gray-700"></i>nuova omologazione
        </router-link>
      </div>
    </div>
  </div>
  <br />
  <TableOmologazioniSoc
    :id_impianto="id_impianto"
    :denominazione_impianto="denominazione_impianto"
  />
  <ModalDocImpianti :id_impianto="id_impianto" />
  <br />
</template>

<script>
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ModalDocImpianti from "@/components/components-fit/impianti/ModalDocImpianti.vue";
import TableOmologazioniSoc from "@/components/components-fit/enti-aggregati/impianti/TableOmologazioniSoc.vue";
import { useRoute } from "vue-router";
import isEnabled from "@/composables/isEnabled.js";

export default {
  components: { ModalDocImpianti, TableOmologazioniSoc },
  props: {
    denominazione_impianto: {
      type: String,
      required: true,
    },
  },
  setup() {
    const route = useRoute(); // prende id
    const id_societa = ref(route.params.id);
    const id_impianto = ref(route.params.id_impianto);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Omologazioni Impianto", [
        "Lista Societa",
        "Dettaglio Societa",
        "Impianti Società",
      ]);
    });
    return { id_societa, id_impianto, isEnabled };
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 200px;
}
</style>
